import styled from "styled-components"
import { pageMargins, remCalc } from "./utils"
import { typography, color } from "../styles/variables"

const StyledSustainStat = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1.25rem;
  font-family: ${typography.type.primary};
  ${pageMargins};
  margin-bottom: ${remCalc(62)};
  &.no-margin-b {
    margin-bottom: 0;
  }
  & .social-stats_text {
    grid-column: 1/5;
    position: relative;
    margin-top: 3rem;
    & h2 {
      @media only screen and (max-width: 1024px) and (min-width: 640px) {
        font-size: 40px;
      }
      @media only screen and (max-width: 640px) {
        font-size: 32px;
      }
      @media only screen and (min-width: 1024px) {
        font-size: 3rem;
      }
      @media only screen and (min-width: 1440px) {
        font-size: ${remCalc(60)};
      }
    }
    & p {
      font-size: 1.25rem;
      line-height: ${remCalc(30)};
    }
    @media only screen and (max-width: 1024px) {
      grid-column: 1/13;
    }
  }

  & .social-stats_container {
    grid-column: 5/13;
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    & .social_stats-row {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
    }
    & .social-stats_item {
      width: 50%;
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      margin: 3rem 0;
      @media only screen and (max-width: 640px) {
        align-items: center;
      }
      & .sustain_stat-title {
        font-size: ${remCalc(72)};
        line-height: ${remCalc(86)};
        letter-spacing: 0.1px;
        text-align: left;

        &.orange {
          color: ${color.header.social_value.color_3};
        }
        &.blue {
          color: ${color.header.social_value.color_2};
        }
        &.red {
          color: ${color.header.jobs.color_3}
        }
        @media only screen and (min-width: 1024px) {
          width: ${remCalc(262)};
          margin: 0 auto;
        }
      }
      & svg {
        @media only screen and (min-width: 1024px) {
          width: 100%;
          margin: 0 auto;
        }
      }
      & p {
        font-size: 1.25rem;
        line-height: ${remCalc(30)};
        width: 80%;

        &:not(.large-para) {
          margin-top: 1.5rem;
        }
        @media only screen and (min-width: 1024px) {
          width: ${remCalc(262)};
          margin: 0 auto;
        }
      }
      & .large-para {
        font-size: 1.5rem;
        line-height: ${remCalc(33)};
      }
      @media only screen and (max-width: 640px) {
        width: 100%;
      }
    }
    @media only screen and (max-width: 1024px) {
      grid-column: 1/13;
    }
  }
`

export default StyledSustainStat
