import React, { useRef, useEffect, useCallback } from "react"
import Layout from "../layouts"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { color } from "../styles/variables"
import Hero from "../components/Hero"
import LetterAnimation from "../animations/LetterAnimation"
import { PageContainer } from "../styles/global"
import NextPage from "../components/NextPage"
import CircleAndText from "../components/CircleAndText"
import JobsContainer from "../components/pages/Jobs"
import ImageSlider from "../components/ImageSlider"
import animationData from "../animations/DESKTOP_MOBILE/06-Jobs.json"
import mobileAnimationData from "../animations/DESKTOP_MOBILE/06-Jobs_MOBILE.json"
import List from "../components/List"
import Item from "../components/List/Item"
import Arrow from "../images/svgs/Explore"
import StyledStats from "../styles/StyledStats"
import Line from "../components/Line"
import Video from "../components/Video"
import StyledSustainStat from "../styles/StyledSustainStat"

const StyledJobs = styled.div`
  /* padding-top: 7rem; */
  @media only screen and (max-width: 1024px) {
    padding-top: 3rem;
  }
  @media only screen and (max-width: 640px) {
    padding-top: 0rem;
  }
  & a {
    color: black;
  }
  & .jobs_red {
    color: ${color.header.jobs.color_3};
  }
  & .jobs_blue {
    color: ${color.header.jobs.color_2};
  }
  & .jobs_light-blue {
    color: ${color.header.jobs.color_1};
  }
  & .stats_image {
    transform: translate(0);
  }
`

const jobs = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      jobs1: file(relativePath: { eq: "jobs-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jobs2: file(relativePath: { eq: "jobs-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jobs3: file(relativePath: { eq: "jobs-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jobs4: file(relativePath: { eq: "jobs-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bgImage: file(relativePath: { eq: "jobs-header.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster: file(relativePath: { eq: "jobs-video-poster.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout location={location}>
      <Hero
        bgImage={data.bgImage}
        bg="black"
        animationData={animationData}
        mobileAnimationData={mobileAnimationData}
        mobileColors={color.header.jobs}
        location={location.pathname}
        headerColor="#A52E5A"
      >
        <LetterAnimation duration={0.7} delay={0.017} initDelay={0.5}>
          <h1
            className="hero_title text-animated"
            data-scroll
            data-scroll-speed="2"
          >
            <span className="line-inner">Jobs</span>
          </h1>
        </LetterAnimation>
      </Hero>
      <PageContainer>
        <CircleAndText image={data.jobs1}>
          <div
            data-scroll
            data-scroll-speed="1"
            className="circle-text_container first-section"
          >
            <p data-scroll data-scroll-speed="1">We create, support and deliver jobs right across the UK. Whether we’re directly employing staff at our Head Office or in our destinations, or we’re working with a partner to ensure the presence of specialists in construction, security or customer service, we ensure that the people who work with us are valued, treated fairly and have access to long-term, sustainable, employment.</p>
            <List device="desktop">
              <Item scrollElementClass="job-creation">
                <p>Job creation</p>
                <Arrow />
              </Item>
              <Item scrollElementClass="community-employment">
                <p>Community employment</p>
                <Arrow />
              </Item>
              <Item scrollElementClass="prison-employment">
                <p>Employment after prison</p>
                <Arrow />
              </Item>
            </List>
          </div>
          <List device="tablet">
            <Item scrollElementClass="job-creation">
              <p>Job creation</p>
              <Arrow />
            </Item>
            <Item scrollElementClass="community-employment">
              <p>Community employment</p>
              <Arrow />
            </Item>
            <Item scrollElementClass="prison-employment">
              <p>Employment after prison</p>
              <Arrow />
            </Item>
          </List>
          <Line />
        </CircleAndText>
        <StyledJobs>
          <StyledStats
            align="flex-start"
            className="headline-stats jobs_stats p-bottom-0 job-creation"
          >
            <div className="stat_item stat_item--aligned">
              <div className="stat_item__inner">
                <div className="stat_title">
                  <h3 className="jobs_red">128,000 +</h3>
                </div>
                <div className="stat_para">
                  <p>
                    Number of jobs across our workplace, retail and leisure
                    destinations.
                  </p>
                </div>
              </div>
              <div className="stat_item__inner">
                <div className="stat_title">
                  <h3 className="jobs_red">44,914</h3>
                </div>
                <div className="stat_para">
                  <p>
                    Number of jobs created in construction through Landsec's
                    development activities over the last decade.
                  </p>
                </div>
              </div>
             </div>
            <div className="stat_item jobs_image stats_image">
              <Img fluid={data.jobs2.childImageSharp.fluid} />
            </div>
          </StyledStats>
          <StyledStats
            align="flex-start"
            className="headline-stats jobs_stats p-top-0"
          >
            <div className="stat_item jobs_image stats_image">
              <Img fluid={data.jobs3.childImageSharp.fluid} />
            </div>
            <div className="stat_item stat_item--aligned">
              <div className="stat_item__inner">
                <div className="stat_title">
                  <h3 className="jobs_red">£60.2m</h3>
                </div>
                <div className="stat_para">
                  <p>
                    The estimated economic contribution of Landsec's employees per
                    year.
                  </p>
                </div>
              </div>
              <div className="stat_item__inner">
                <div className="stat_title">
                  <h3 className="jobs_red">£12.7m</h3>
                </div>
                <div className="stat_para">
                  <p>
                    Landsec’s annual employment tax contribution for the last tax
                    year.
                  </p>
                </div>
              </div>
            </div>
            <Line />
          </StyledStats>
          <div className="page_title-subtitle grid-wrapper community-employment section-padding">
            <div className="title red">
              <h2 className="jobs_light-blue">Community employment</h2>
            </div>
            <div className="subtitle">Our community employment programme is helping address socio-economic challenges that affect people living in our communities. Working closely with our industry partners and customers, we continue to fill significant skills gaps in construction, facilities management and customer service, supporting some of the most excluded groups in our society to find employment. These include people experiencing homelessness, young people, and prison leavers.
            </div>
            <Line />
          </div>
          <StyledSustainStat className="no-margin-b prison-employment">
            <div className="social-stats_text prison">
              <h2 className="jobs_blue">Employment after prison</h2>
            </div>
            <div className="social-stats_container">
              <div className="social_stats-row">
                <div className="social-stats_item">
                  <h2 className="sustain_stat-title jobs_light-blue">121</h2>
                  <p>People supported into work through our community employment programme in 2020/2021</p>
                </div>
              </div>
            </div>
          </StyledSustainStat>
          <Video
            url="https://www.youtube.com/watch?v=ymJ5CDaJdfg&feature=emb_logo"
            caption="Onu's story of employment following prison."
            poster={data.poster.childImageSharp.fluid}
          />
          <div className="page_title-subtitle grid-wrapper community-employment section-padding">
            <div className="title red">
              <h3 className="jobs_red">Inspiring the next generation</h3>
            </div>
            <div className="subtitle">
              <p>This year, we’ve continued our commitment to helping young people from diverse backgrounds enter careers in property, in particular those who face barriers and risk becoming disengaged, running our education programmes. Our aim is to provide role models for students, alongside transferable skills, industry insights and career opportunities.</p>
            </div>
          </div>
          <StyledSustainStat className="no-margin-b">
            <div className="social-stats_text coaching">
              <p>In London, we ran two Circl reverse coaching programmes, where over 30 Landsec employees coached – and were coached by – young people from diverse and under-represented backgrounds. Evidence shows this can increase a young person’s chance of reaching their potential through employment, education or training. Circl not only supports young people in becoming future leaders through regular coaching by industry professionals, it also helps our workforce develop their own managerial and leadership skills.</p>
            </div>
            <div className="social-stats_container">
              <div className="social_stats-row">
                <div className="social-stats_item">
                  <h2 className="sustain_stat-title red">75%</h2>
                  <p>of participants interested in a career at Landsec</p>
                </div>
                <div className="social-stats_item">
                  <h2 className="sustain_stat-title red">1,248</h2>
                  <p>hours of coaching training</p>
                </div>
                <Line />
              </div>
              <div className="social_stats-row">
                <div className="social-stats_item">
                  <h2 className="sustain_stat-title red">88%</h2>
                  <p>of Landsec employees involved say they now feel confident using coaching skills</p>
                </div>
                <div className="social-stats_item">
                  <h2 className="sustain_stat-title red">87%</h2>
                  <p>of young participants felt Circl helped them develop useful leadership skills</p>
                </div>
              </div>
            </div>
          </StyledSustainStat>
          <NextPage
            page={{
              title: "Environmental sustainability",
              path: "sustainability",
            }}
            colorKey="sustain"
          />
        </StyledJobs>
      </PageContainer>
    </Layout>
  )
}

export default jobs
