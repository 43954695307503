import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { useGesture, useWheel } from "react-use-gesture"
import { useSpring, animated, interpolate } from "react-spring"
import { trans } from "../../lib/helperFunctions"
const StyledSlider = styled.section`
  &.tiles {
    height: 36vw;
    position: relative;
    overflow: hidden;
  }

  &.tiles--oneline {
    min-height: 400px;
    margin: 2vh 0;
  }
  .tiles__wrap {
    width: 150%;
    height: 40vh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  .tiles__line {
    display: flex;
    height: 100%;
  }
  .tiles--oneline .tiles__line {
    height: 100%;
  }
  .tiles__line-img {
    flex: none;
    margin: 2vw;
    background-size: cover;
    background-position: 50% 50%;
    margin: 0 1vw;
    width: calc(25% - 1vw * 2);
    height: 100%;
  }
`

const ImageSlider = () => {
  return (
    <div className="slider_container full-width ">
      <StyledSlider className="full-width tiles tiles--oneline" id="grid2">
        <div className="tiles__wrap">
          <div
            className="tiles__line"
            data-scroll
            data-scroll-speed="-2"
            data-scroll-direction="horizontal"
          >
            <div
              className="tiles__line-img"
              style={{ backgroundColor: "black" }}
            ></div>
            <div
              className="tiles__line-img"
              style={{ backgroundColor: "black" }}
            ></div>
            <div
              className="tiles__line-img"
              style={{ backgroundColor: "black" }}
            ></div>
            <div
              className="tiles__line-img"
              style={{ backgroundColor: "black" }}
            ></div>
          </div>
        </div>
      </StyledSlider>
      <StyledSlider className="tiles full-width tiles--oneline" id="grid3">
        <div className="tiles__wrap">
          <div
            className="tiles__line"
            data-scroll
            data-scroll-speed="2"
            data-scroll-direction="horizontal"
          >
            <div
              className="tiles__line-img"
              style={{ backgroundColor: "black" }}
            ></div>
            <div
              className="tiles__line-img"
              style={{ backgroundColor: "black" }}
            ></div>
            <div
              className="tiles__line-img"
              style={{ backgroundColor: "black" }}
            ></div>
            <div
              className="tiles__line-img"
              style={{ backgroundColor: "black" }}
            ></div>
          </div>
        </div>
      </StyledSlider>
    </div>
  )
}

export default ImageSlider
